export const projects = [
    {
        title: 'Movie Swiper',
        link: '/movie-swiper',
        tech: ['React', 'TypeScript', 'Node', 'SocketIO'],
        description: 'Vote as a group to find the perfect movie.',
        images: {
            sm: require('../../images/lg/movie-swiper.png'),
            md: require('../../images/lg/movie-swiper.png'),
            lg: require('../../images/lg/movie-swiper.png'),
        },
        borderColor: '#293C50',
    },
    {
        title: 'E-Commerce Store',
        link: '/e-commerce',
        tech: ['NextJS', 'Node', 'Express'],
        description: "Stylish headphone's e-commerce store.",
        images: {
            sm: require('../../images/sm/e-commerce-sm.png'),
            md: require('../../images/md/e-commerce-md.png'),
            lg: require('../../images/lg/e-commerce-lg.png'),
        },
        borderColor: '#00c7bb',
    },
    // {
    //     title: 'Movie Explorer',
    //     link: '/movie-explorer',
    //     tech: ['React', 'Redux', 'Node', 'Express'],
    //     description:
    //         'Movie library that allows you to create a personal watchlist.',
    //     images: {
    //         sm: require('../../images/sm/movie-explorer-sm.png'),
    //         md: require('../../images/md/movie-explorer-md.png'),
    //         lg: require('../../images/lg/movie-explorer-lg.png'),
    //     },
    //     borderColor: '#8f8580',
    // },
    // {
    //     title: 'Dog Finder',
    //     link: '/dog-finder',
    //     tech: ['React', 'Node', 'Express', 'Styled Components'],
    //     description: 'Personalized results of dogs for adoption in your area.',
    //     images: {
    //         sm: require('../../images/sm/pet-matcher-sm.png'),
    //         md: require('../../images/md/pet-matcher-md.png'),
    //         lg: require('../../images/lg/pet-matcher-lg.png'),
    //     },
    //     borderColor: '#d3c47e',
    // },
    {
        title: 'Listify',
        link: '/listify',
        tech: ['React', 'Node', 'PostgreSQL', 'SocketIO'],
        description: 'Real-time, collaborative lists.',
        images: {
            sm: require('../../images/sm/listify-sm.png'),
            md: require('../../images/md/listify-md.png'),
            lg: require('../../images/lg/listify-lg.png'),
        },
        borderColor: '#38405f',
    },
    // {
    //     title: 'Exercise Landing Page',
    //     link: '/exercise-site',
    //     tech: ['HTML', 'CSS'],
    //     description: 'Mock product landing page.',
    //     images: {
    //         sm: require('../../images/sm/exercise-site-sm.png'),
    //         md: require('../../images/md/exercise-site-md.png'),
    //         lg: require('../../images/lg/exercise-site-lg.png'),
    //     },
    //     borderColor: '#7116b9',
    // },
    // {
    //     title: 'Life Tracker',
    //     link: '/life-tracker',
    //     tech: ['React', 'Node', 'Express', 'MongoDB'],
    //     description:
    //         'Track your groceries, personal appointments, favorite books and more.',
    //     images: {
    //         sm: require('../../images/sm/life-tracker-sm.png'),
    //         md: require('../../images/md/life-tracker-md.png'),
    //         lg: require('../../images/lg/life-tracker-lg.png'),
    //     },
    //     borderColor: '#8adae6',
    // },
    // {
    //   title: 'Pet Store Landing Page',
    //   link: '/exotic-pet-store',
    //   tech: ['JS', 'HTML', 'CSS'],
    //   description: 'Mock store landing page.',
    //   images: {
    //     sm: require('../../images/sm/exotic-pet-store-sm.png'),
    //     md: require('../../images/md/exotic-pet-store-md.png'),
    //     lg: require('../../images/lg/exotic-pet-store-lg.png'),
    //   },
    //   borderColor: '#779730',
    // },
];
